import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Delete, Get, Post } from "../../Adapters/xhr";
import { UserContext } from "../../App";
import MUIDialog from "../../components/Dailog/MUIDialog";
import DataTable from "../../components/DataTable/MUITable";
import UpdatePayment from "../AddPayment/UpdatePayment";
import { getProductNameByCode, toCommaAmount } from "../../Constants/constants";
import SingleIncomePrint from "./SingleIncomePrint";

export default function IncomeYesterday() {
    const [allPayments, setAllPayments] = useState([]);
    const [loggedInUser, setLoggedInUser] = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [updateData, setUpdateData] = useState(null);
    const [totalAmount, setTotalAmount] = useState("0");
    const [open2, setOpen2] = useState(false);
    const [rowData, setRowData] = useState(null);

    const getAllPayments = async () => {
        try {
            const response = await Get("payment");
            console.log("log:", response);

            if (response.data !== false) {
                const today = new Date();
                today.setDate(today.getDate() - 1);
                const thisMonth = today.getMonth();
                const thisYear = today.getFullYear();

                const todaysData = await Promise.all(
                    response.data.data.map(async (data) => {
                        const createdDate = new Date(data.date_time).getDate();
                        const createdMonth = new Date(data.date_time).getMonth();
                        const createdYear = new Date(data.date_time).getFullYear();

                        if (thisYear === createdYear && thisMonth === createdMonth && createdDate === today.getDate()) {
                            data.product_name = await getProductNameByCode(data?.product_id);
                            return data;
                        }
                        return null;
                    })
                ).then((data) => data.filter((item) => item !== null));

                const computedTotalAmount = todaysData.reduce((prev, cur) => prev + +cur.amount, 0);
                setTotalAmount(toCommaAmount(computedTotalAmount.toString()));
                setAllPayments(todaysData);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            toast.warn("Try Again");
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllPayments();
    }, [open]);

    const columns = [
        { field: "product_name", title: "Name", width: 70 },
        { field: "description", title: "Description", width: 70 },
        { field: "amount", title: "Amount", width: 70 },
        { field: "customer", title: "Customer", width: 70 }
    ];

    const handleDelete = async (rowData) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                Delete(`payment/deletepayment/${rowData._id}`)
                    .then((result) => {
                        setAllPayments(result.data);
                        Swal.fire("Deleted!", "Your file has been deleted.", "success");
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.warn("Try Again!");
                    });
            }
        });
    };

    // For print
    const handlePrint = (rowData) => {
        setRowData(rowData);
        setOpen2(true);
        console.log("hit");
    };

    return (
        <div>
            {!loading && (
                <DataTable
                    loading={loading}
                    tableTitle="Yesterday Income"
                    columns={columns}
                    rows={allPayments}
                    handleEdit={(rowData) => {
                        setUpdateData(rowData);
                        setOpen(true);
                    }}
                    handlePrint={(rowData) => handlePrint(rowData)}
                    handleDelete={handleDelete}
                />
            )}
            {loading && (
                <p>
                    Loading..<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;
                    <br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;
                </p>
            )}
            <MUIDialog open={open} setOpen={setOpen} content={<UpdatePayment updateData={updateData} setOpen={setOpen} />} />
            <MUIDialog open={open2} setOpen={setOpen2} content={<SingleIncomePrint rowData={rowData} />} />
            <p>
                <b>Total: {totalAmount}</b>
            </p>
        </div>
    );
}
