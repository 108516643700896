import React from 'react';

const AboutUs = () => {
    return (
        <div className='bg-white min-h-screen container mx-auto py-[52px] px-4 md:px-0'>

            <div className='flex flex-col md:flex-row gap-[67px] items-center justify-center '>
                <img className='h-[280px] w-[186px]' src="/assets/about1.png" alt=""/>
                <div className=''>
                    <h1 className='font-bold text-[#003358] text-[40px] font-[Montserrat] '>About Us</h1>
                    <p className=' text-[#000] text-[20px] font-[Montserrat] text-justify'>SalesLine is an innovative
                        and cutting-edge AI-powered Salesbook & Inventory Management application, meticulously crafted
                        to address the sales tracking challenges faced by enterprises across diverse scales. Its
                        sophisticated capabilities empower businesses of all sizes, offering a comprehensive solution to
                        streamline sales operations and enhance inventory management efficiency.</p>
                    <p className=' text-[#000] text-[20px] font-[Montserrat] text-justify'>The application effectively
                        eradicates paper-based procedures in sales and inventory management, delivering a bespoke sales
                        software solution of superior quality. SalesLine offers a comprehensive array of features,
                        encompassing inventory management, customer relationship management, retail accounting, and
                        income and expense tracking. This enables administrators to make informed managerial decisions,
                        automate bookkeeping tasks, and generate real-time reports on the business's status.</p>
                    <p className=' text-[#000] text-[20px] font-[Montserrat] text-justify'>The application's
                        distinct competitive advantage stems from its AI-driven automation, which optimizes
                        inventory tracking, ensures adequate stock availability to fulfill customer orders, and
                        enhances the efficiency of retail accounting by valuing inventory based on selling price.</p>
                    <div className='h-[1px] mt-[21px] bg-[#003358]'/>

                </div>
            </div>
            <div className='flex flex-col md:flex-row gap-[67px] mt-[100px] md:mt-[20px]  items-center justify-center '>
                <img className='h-[280px] w-[186px]' src="/assets/about2.png" alt=""/>
                <div>
                    <h1 className='font-bold text-[#003358] text-[40px] font-[Montserrat] '>What It Does</h1>
                    <p className=' text-[#000] text-[20px] font-[Montserrat] text-justify'>
                        <ol>
                            <li>
                                <b>Streamlining Sales and Inventory Management: </b><br/>
                                Say goodbye to traditional paper-based processes! SalesLine's advanced technology
                                eliminates the need for manual data entry, reducing errors and delays. Our app enables
                                businesses to efficiently manage their sales and inventory in a digital, user-friendly
                                interface.
                            </li>
                            <li>
                                <b>Inventory Management:</b><br/>
                                Inventory tracking can be a cumbersome task, but not with SalesLine. Our AI-driven
                                automation optimizes inventory tracking, ensuring that you always have enough stock to
                                fulfill customer orders. No more stockouts or overstocking - SalesLine keeps your
                                inventory levels optimized for maximum efficiency.

                            </li>
                            <li>
                               <b>Customer Management:</b> <br/>
                                Building strong customer relationships is essential for any business. With SalesLine,
                                you can effortlessly manage your customer data, track interactions, and maintain
                                personalized profiles. This helps you understand your customers better and cater to
                                their specific needs.

                            </li>
                            <li>
                                <b>Retail Accounting:</b><br/>
                                Managing finances is crucial for any business's success. SalesLine takes care of your
                                retail accounting needs by automating bookkeeping processes. Our app provides accurate
                                financial data, helping you maintain clean and up-to-date records.

                            </li>
                            <li>
                               <b> Income and Expense Management:</b><br/>
                                Keep track of your revenue and expenses with ease. SalesLine offers comprehensive income
                                and expense management features, giving you a clear overview of your financial
                                performance.
                            </li>
                            <li>
                                <b>Real-time Business Insights:</b><br/>
                                Gone are the days of making decisions based on outdated data. SalesLine provides
                                real-time insights into your business's performance, enabling you to make well-informed
                                managerial decisions.

                            </li>
                            <li>

                                Reports and Analytics:<br/>
                                Stay on top of your business's minute-by-minute status with our comprehensive reports
                                and analytics. SalesLine generates detailed reports that give you a comprehensive view
                                of your sales and inventory performance.

                            </li>
                            <li>

                                Enhancing Financial Efficiency:<br/>
                                Valuing inventory based on the selling price enhances your retail accounting efficiency,
                                improving financial decision-making and profit margins.

                            </li>
                        </ol>


                    </p>
                    <div className='h-[1px] mt-[21px] bg-[#003358]'/>

                </div>
            </div>
            <div className='flex flex-col md:flex-row gap-[67px] mt-[100px] md:mt-[20px]  items-center justify-center '>

                <img className='h-[280px] w-[186px]' src="/assets/about3.png" alt=""/>
                <div>
                    <h1 className='font-bold text-[#003358] text-[40px] font-[Montserrat] '>Our Mission</h1>
                    <p className=' text-[#000] text-[20px] font-[Montserrat] text-justify'>At SalesLine, our mission is
                        clear and steadfast - we strive to empower businesses of all sizes to overcome the challenges of
                        sales tracking and inventory management. We believe that innovation and technology can
                        revolutionize the way companies operate, and our AI-powered Salesbook & Inventory Management app
                        is at the forefront of this transformation.
                        <br/><br/>
                        We understand the pain points faced by businesses when it comes to sales and inventory
                        management. Traditional paper-based processes lead to inefficiencies, errors, and a lack of
                        real-time data. This reliance on manual methods can hinder growth and hinder a company's ability
                        to make informed decisions. That's where SalesLine comes in.
                        <br/><br/>
                        Our AI-powered app is designed with one goal in mind - to eliminate the hurdles posed by
                        traditional methods and provide a high-quality custom sales software solution. By harnessing the
                        power of artificial intelligence, SalesLine streamlines inventory management, customer
                        management, retail accounting, and income and expense tracking. With these advanced features at
                        their fingertips, administrators can take charge of their business with confidence.
                        <br/><br/>
                        The secret sauce of SalesLine lies in the AI-driven automation that optimizes inventory
                        tracking. No more guesswork or overstocking; our app ensures that there is always enough stock
                        to fulfill customer orders, enhancing customer satisfaction and retention. Moreover, we value
                        inventory based on selling price, leading to improved retail accounting efficiency and better
                        financial decision-making.
                        <br/><br/>
                        Our dedication to solving the problem of inefficient sales tracking and inventory management is
                        unwavering. We know that businesses need accurate, real-time insights to stay ahead in today's
                        competitive landscape. SalesLine provides just that - it automates bookkeeping processes and
                        generates reports for the minute-by-minute status of the business. Armed with these data-driven
                        insights, administrators can make well-informed managerial decisions and strategize for growth.
                        <br/><br/>
                        We take pride in being a partner in our customers' success stories. By addressing the customer
                        problem of relying on paper-based processes, we enable businesses to operate with agility and
                        precision. Our AI-powered app not only saves time and resources but also empowers administrators
                        to optimize inventory levels and enhance financial efficiency.
                        <br/><br/>
                        In essence, our mission is to drive positive change in how businesses manage their sales and
                        inventory. We envision a future where our technology enables businesses to focus on their core
                        competencies while leaving the complexities of sales and inventory management to SalesLine.
                        <br/><br/>
                        Join us in our mission to embrace innovation, efficiency, and growth. Experience the power of
                        AI-driven sales and inventory management with SalesLine. Together, let's unlock the true
                        potential of your business!


                    </p>
                    <div className='h-[1px] mt-[21px] bg-[#003358]'/>

                </div>
            </div>
        </div>
    );
};

export default AboutUs;
