import React from 'react'


export default function PublicFooter() {
    const today = new Date();
    const thisYear = today.getFullYear();

    return (
        <div className='bg-[#003358] text-center  h-[106px] flex justify-center items-center'>
            <p className='text-[20px] leading-[30px] font-[Roboto] font-bold text-white'>Copyright ©2023 - {thisYear} <a
                href="https://salesline.click" target="_blank">SalesLine Technology Enterprises</a></p>
        </div>
    )
}
