import * as React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {UserContext} from '../App';
import {getAuth, GoogleAuthProvider, signOut} from "firebase/auth";
// Import necessary Firebase modules
import {initializeApp} from "firebase/app";
import {firebaseConfig} from "../Constants/firebase";

const pages = [];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

// Initialize Firebase app (you can do this in firebase.js if you prefer)
const app = initializeApp(firebaseConfig);

// Get the Auth instance
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

const ResponsiveAppBar = () => {

    const [loggedInUser, setLoggedInUser] = React.useContext(UserContext)

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const history = useHistory();

    const logOut = async () => {
        try {
            await signOut(auth);
        } catch (err) {
            console.error(err);
        }
    };


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    return (
        <div className="bg-[#003358] px-[52px] py-[22px] flex justify-between items-center">
            <Link to='/'><img src="/assets/logo-horizontal.png" alt=""/></Link>
            <div className="text-white flex items-center gap-[22px]">
                <p className='text-[20px]'>Welcome <span className='font-semibold'>
                    {loggedInUser.user.name?
                    loggedInUser.user.name:
                    loggedInUser.user.username}!</span>
                </p>
                <p className='text-[20px]'><Link to='/help-and-support'>Help &amp; Support </Link></p>
                <div className="h-[50px] w-[50px] rounded-full border-[#003358] border-[5px] bg-white"
                     style={{boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}></div>
                <button onClick={() => [
                    setLoggedInUser({user_token: ""}),
                    logOut
                ]}
                        className='bg-[#B38B00] text-[20px] py-[14px] px-[42px] rounded-[17px]'>Log Out
                </button>
            </div>
        </div>
    );
};
export default ResponsiveAppBar;
