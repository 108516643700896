import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Delete, Get, Post } from "../../Adapters/xhr";
import { UserContext } from "../../App";
import MUIDialog from "../../components/Dailog/MUIDialog";
import DataTable from "../../components/DataTable/MUITable";
import UpdatePayment from "../AddPayment/UpdatePayment";
import { getProductNameByCode, toCommaAmount } from "../../Constants/constants";
import SingleIncomePrint from "./SingleIncomePrint";
import { Chart } from "../../components";

export default function IncomeLastWeek() {
    const [allPayments, setAllPayments] = useState([]);
    const [filteredIncome, setFilteredIncome] = useState([]);
    const [loggedInUser, setLoggedInUser] = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [updateData, setUpdateData] = useState(null);
    const [totalAmount, setTotalAmount] = useState("0");
    const [open2, setOpen2] = useState(false);
    const [rowData, setRowData] = useState(null);

    const getWeekDayNumber = () => {
        const d = new Date();
        return d.getDay();
    };

    let WEEK_DAY_NUMBER = getWeekDayNumber();
    const today = new Date();
    const thisYear = today.getFullYear();
    const lastWeekStarting = today.getDate() - WEEK_DAY_NUMBER - 7;
    const thisWeekStarting = today.getDate() - WEEK_DAY_NUMBER;

    let theFilteredIncome;

    const filterAllIncome = (thisYearsData) => {
        try {
            console.log("inside filterAllIncome");
            let incomeMonth = 0;
            const chartDataPoints = [
                { label: 'Sun', value: 0 },
                { label: 'Mon', value: 0 },
                { label: 'Tue', value: 0 },
                { label: 'Wed', value: 0 },
                { label: 'Thur', value: 0 },
                { label: 'Fri', value: 0 },
                { label: 'Sat', value: 0 },
            ];

            thisYearsData.forEach((incomeObject, index) => {
                incomeMonth = new Date(incomeObject.date_time).getDay();
                chartDataPoints[incomeMonth].value += +incomeObject.amount;
            });

            return <Chart dataPoints={chartDataPoints} />;
        } catch (error) {
            console.error("This error >> " + error);
        }
    };

    const getAllPayments = async () => {
        try {
            const response = await Get("payment");

            if (response.data === false) return;

            const lastWeeksData = [];
            const day = new Date();
            const thisMonth = day.getMonth();

            const paymentPromises = response.data.data.map(async (data) => {
                const date = new Date(data.date_time);
                const createdDate = date.getDate();
                const createdMonth = date.getMonth();
                const createdYear = date.getFullYear();

                if (thisYear === createdYear && thisMonth === createdMonth) {
                    if (lastWeekStarting <= createdDate && createdDate <= thisWeekStarting) {
                        const productName = await getProductNameByCode(data?.product_id);
                        lastWeeksData.push({
                            ...data,
                            customer: data?.customer,
                            product_name: productName,
                            description: data?.description,
                        });
                    }
                }
            });

            await Promise.all(paymentPromises);

            setAllPayments(lastWeeksData);
            setLoading(false);
        } catch (error) {
            toast.warn("Try Again");
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllPayments();
    }, [open]);

    const columns = [
        { field: "product_name", title: "Name", width: 70 },
        { field: "description", title: "Description", width: 70 },
        { field: "amount", title: "Amount", width: 70 },
        { field: "customer", title: "Customer", width: 70 },
    ];

    const handleDelete = async (rowData) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Delete(`payment/deletepayment/${rowData._id}`)
                    .then((result) => {
                        setAllPayments(result.data);
                        Swal.fire("Deleted!", "Your file has been deleted.", "success");
                    })
                    .catch((err) => {
                        toast.warn("Try Again!");
                    });
            }
        });
    };

    const handlePrint = (rowData) => {
        setRowData(rowData);
        setOpen2(true);
        console.log("hit");
    };

    return (
        <div>
            {!loading && (
                <DataTable
                    loading={loading}
                    tableTitle="Last Week Income"
                    columns={columns}
                    rows={allPayments}
                    handleEdit={(rowData) => {
                        setUpdateData(rowData);
                        setOpen(true);
                    }}
                    handleDelete={handleDelete}
                    handlePrint={(rowData) => handlePrint(rowData)}
                />
            )}
            {loading && (
                <p>
                    Loading..<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br />
                    &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;<br /> &nbsp;
                </p>
            )}
            <MUIDialog open={open} setOpen={setOpen} content={<UpdatePayment updateData={updateData} setOpen={setOpen} />} />
            <MUIDialog open={open2} setOpen={setOpen2} content={<SingleIncomePrint rowData={rowData} />} />
            <p>
                <b>Total: {totalAmount}</b>
            </p>
            {filterAllIncome(allPayments)}
        </div>
    );
}
