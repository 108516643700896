import React, {useEffect, useState} from "react";
import axios from "axios";
import {baseUrl, GET_ALL_CONTEXTS_URL, READ_DOC_URL} from "../../../Constants/constants";

function ChatArea() {
    const hero_right_img = '/assets/hero_right_img.png';
    const [selectedContext, setSelectedContext] = useState("");
    const [allContext, setAllContext] = useState([]);
    const [conversation, setConversation] = useState("");
    const [question, setQuestion] = useState("");
    const [savedQuestion, setSavedQuestion] = useState("");
    const [answer, setAnswer] = useState(null);
    const [loading, setLoading] = useState(false); // Added loading state

    const getAllContexts = async () => {
        try {
            const response = await axios.get(`${baseUrl}${GET_ALL_CONTEXTS_URL}`);
            const resData = response.data;
            // loop through the resData array and get all the obj.name properties and save them in a set   and then convert the set to an array
            const allContexts = [...new Set(resData.map((obj) => obj.name))];
            console.log("allContexts: ", Array.of(allContexts))
            if (response.status === 200) {
                setAllContext(allContexts);
            } else {
                alert("An Error Occurred")
            }
        } catch (error) {
            console.log("error CXT : ", error);
            alert("A " + JSON.stringify(error.message) + " Error Occurred");
        }
    };

    useEffect(() => {
        getAllContexts();
    }, []);

    const handleQuestionChange = (e) => {
        setQuestion(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading state to true
        try {
            if (question && selectedContext) {
                console.log({
                    question,
                    context: selectedContext,
                });
                const result = await axios.post(`${baseUrl}${READ_DOC_URL}`, {
                    question,
                    context: selectedContext,
                });
                if (result.data.statusCode === 200) {
                    // console.log(result);
                    const returned = result.data;
                    setAnswer(returned.body);
                    setSavedQuestion(question);

                    const theConversation =
                        "Question: \n" + question + "\n" + "Answer: \n" + returned.body + "\n";

                    setConversation(theConversation);
                } else {
                    alert("An Error Occurred")
                }
            } else {
                alert("Type your question")
            }
        } catch (error) {
            console.log("error: ", error);
            alert("A " + JSON.stringify(error.message) + " Error Occurred");
        } finally {
            setLoading(false); // Set loading state to false after request completion
            setQuestion(""); // Empty the question input field
        }
    };

    const selectHandleChangeContext = (event) => {
        setSelectedContext(event.target.value);
    };

    function QuestionBlock({savedQuestion}) {
        return (
            <div>
                <div className="text-sm italic ml-8">Question</div>
                <div className="bg-green-100 rounded-lg border-black border mb-4 p-4 ml-8">
                    {savedQuestion}
                </div>
            </div>
        );
    }

    function AnswerBlock({answer}) {
        const [displayAnswer, setDisplayAnswer] = useState("");

        useEffect(() => {
            if (answer) {
                const words = answer.split(" ");
                let currentWordIndex = 0;
                let updatedAnswer = ""; // New variable to hold the updated answer
                const interval = setInterval(() => {
                    updatedAnswer += words[currentWordIndex] + " "; // Append the current word to the updated answer
                    setDisplayAnswer(updatedAnswer.trim()); // Update the displayAnswer state with the updated answer (remove trailing whitespace)
                    currentWordIndex++;
                    if (currentWordIndex >= words.length) {
                        clearInterval(interval);
                    }
                }, 250);
            }
        }, [answer]);

        return (
            <div>
                <div className="text-right text-sm italic mr-8">Answer</div>
                <div className="bg-blue-100 rounded-lg border-black border mb-4 p-4 mr-8">
                    {displayAnswer}
                </div>
            </div>
        );
    }


    return (
        <>
            <div className="pt-[116px] pb-[76px] relative hero_sec max-w-[1480px] mx-auto">
                <div className="bg-white-100 pl-8">
                    {/* TEXT */}
                    <div className="mb-12">
                        {loading ? <p className="ml-16 p-[20px]">Loading...</p> :
                            <p className="text-f_18_l_21 text-grey-500 max-w-[627px] mb-[78px] p-[20px]">
                                <div>
                                    {savedQuestion && <QuestionBlock savedQuestion={savedQuestion}/>}
                                </div>
                                <div>{answer && <AnswerBlock answer={answer}/>}</div>
                            </p>}
                    </div>

                    {/* PARAGRAPH */}
                    <form>
                        <div className="grid grid-cols-2 gap-x-9 mb-[12px]">
                            <div className="px-3 space-y-12 mb-[18px] ml-[78px]">
                                <select
                                    className="text-f_20_l_26 text-black-200 font-roboto"
                                    id="demo-simple-select-standard"
                                    value={selectedContext}
                                    onChange={selectHandleChangeContext}
                                    required
                                >
                                    <option value="">--Select the context--</option>
                                    {allContext.map((ctx) => {
                                        return (
                                            <option key={ctx} value={ctx}>
                                                {ctx}
                                            </option>
                                        );
                                    })}
                                </select>
                                <textarea
                                    className="rounded-lg border-black border w-full text-f_18 placeholder:text-grey-400 p-[20px]"
                                    placeholder="Type Your Question Here"
                                    value={question}
                                    onChange={handleQuestionChange}
                                    id="question"
                                    rows={3} // Added to allow multiple lines of text
                                    required
                                />
                                {/* BUTTON */}
                                <div className="pl-11">
                                    <button
                                        onClick={handleSubmit}
                                        className="w-full max-w-[353.31px] h-[79px] rounded-[44px] bg-brown text-f_32_l_37 text-white font-bold"
                                    >
                                        {loading ? "Loading..." : "Submit"} {/* Display "Loading..." when loading state is true */}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* RIGHT IMAGE */}
            <img
                className="absolute bottom-5 right-0 top-80"
                src={hero_right_img}
                alt="hero_img"
            />
        </>
    );
}

export default ChatArea;
