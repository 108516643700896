import ChatArea from "./components/ChatArea.jsx";
import React from "react";

function ChatHome() {
  return (
    <>
      <ChatArea/>
    </>
  );
}

export default ChatHome;
