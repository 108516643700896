import { useEffect, useState } from "react";
import axios from "axios";
import {baseUrl, GET_ALL_CONTEXTS_URL} from "../../../Constants/constants";

function AllContexts() {
    const [contexts, setContexts] = useState([]);

    useEffect(() => {
        getContexts();
    }, []);

    const getContexts = async () => {
        try {
            const response = await axios.get(`${baseUrl}/${GET_ALL_CONTEXTS_URL}`);
            setContexts(response.data.body);
        } catch (error) {
            console.log("Error:", error);
            alert("A " + JSON.stringify(error.message) + " Error Occurred");
        }
    };

    return (
        <div className="text-black-300 font-ramabhadra font-black mb-32 ml-16">
            <h3 className="uppercase text-black-300 text-f_36_l_68 font-ramabhadra font-black mb-8">
                All Contexts
            </h3>

            <table className="border-collapse w-full">
                <thead>
                <tr>
                    <th className="border border-gray-400 p-[8px]">ID</th>
                    <th className="border border-gray-400 p-[8px]">Key</th>
                    <th className="border border-gray-400 p-[8px]">Value</th>
                </tr>
                </thead>
                <tbody>
                {contexts.map((context) => (
                    <tr key={context.id}>
                        <td className="border border-gray-400 p-[8px]">{context.id}</td>
                        <td className="border border-gray-400 p-[8px]">{context.key}</td>
                        <td className="border border-gray-400 p-[8px]">{context.value}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default AllContexts;
