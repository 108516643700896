// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
    apiKey: "AIzaSyDrBkDp-zeEm_7ZVtel5YbHHgM55yEHcJM",
    authDomain: "salesline-c91ba.firebaseapp.com",
    databaseURL: "https://salesline-c91ba.firebaseio.com",
    projectId: "salesline-c91ba",
    storageBucket: "salesline-c91ba.appspot.com",
    messagingSenderId: "1067756562365",
    appId: "1:1067756562365:web:9038849c235e72473fd4d4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
