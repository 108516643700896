import React, { useContext, useEffect, useState } from "react";
import { Chart } from '../../../components/index';
import { UserContext } from "../../../App";
import {Get, Post} from "../../../Adapters/xhr";
import { toCommaAmount } from "../../../Constants/constants";
import { toast } from "react-toastify";

const DailyIncomeChartLastW = () => {
    const [allpayment, setallpayment] = useState([]);
    const [filteredIncome, setFilteredIncome] = useState([]);
    const [loggedInUser, setLoggedInUser] = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [updateData, setUpdateData] = useState(null);
    const [totalAmount, setTotalAmount] = useState("0");

    const filterAllIncome = (thisYearsData) => {
        try {
            let incomeMonth = 0;
            const chartDataPoints = [
                { label: 'Sun', value: 0 },
                { label: 'Mon', value: 0 },
                { label: 'Tue', value: 0 },
                { label: 'Wed', value: 0 },
                { label: 'Thur', value: 0 },
                { label: 'Fri', value: 0 },
                { label: 'Sat', value: 0 },
            ];

            thisYearsData.forEach((incomeObject, index) => {
                incomeMonth = new Date(incomeObject.date_time).getDay();
                chartDataPoints[incomeMonth].value += +incomeObject.amount;
            });

            return chartDataPoints;
        } catch (error) {
            console.error("This error >> " + error);
            return null;
        }
    };

    const getWeekDayNumber = () => {
        const d = new Date();
        return d.getDay();
    };

    let WEEK_DAY_NUMBER = getWeekDayNumber();
    const today = new Date();
    const lastWeekStarting = today.getDate() - WEEK_DAY_NUMBER - 7;
    const thisWeekStarting = today.getDate() - WEEK_DAY_NUMBER;

    const getAllPayments = async () => {
        try {
           const response = await Get("payment");

            console.log('Daily  Last Week lyIncomeChart response data' , JSON.stringify(response.data))

            if (!response.data === false) {
                const lastWeekssData = [];

                response.data.data.forEach((data) => {
                    const date = data.date_time;
                    const createdDate = new Date(date).getDate();
                    const day = new Date();
                    const thisMonth = day.getMonth();
                    const createdMonth = new Date(date).getMonth();

                    if (thisMonth === createdMonth) {
                        if (
                            lastWeekStarting <= createdDate &&
                            createdDate <= thisWeekStarting
                        ) {
                            console.log("push: ", {
                                ...data,
                                customer: data?.customer,
                                product_name: data?.description,
                                organization: data?.organization_id
                            });
                            lastWeekssData.push({
                                ...data,
                                customer: data?.customer?.customer_name,
                                product_name: data?.product?.name,
                                customer_info: data?.customer,
                            });

                            const theFilteredIncome = filterAllIncome(lastWeekssData);
                            setFilteredIncome(theFilteredIncome);

                            let computedTotalAmount = lastWeekssData.reduce(function (prev, cur) {
                                return prev + +cur.amount;
                            }, 0);

                            computedTotalAmount = toCommaAmount(computedTotalAmount + "");
                            setTotalAmount(computedTotalAmount);
                        }
                    }
                });
                setallpayment(lastWeekssData);
            }
            setLoading(false);
        } catch (error) {
            toast.warn("Try Again");
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllPayments();
    }, []);

    return (
        <div>
            {filteredIncome && (
                <Chart dataPoints={filteredIncome} />
            )}
        </div>
    );
};

export { DailyIncomeChartLastW };
