import React, {useContext, useEffect} from 'react'
import {UserContext} from "../../App";
import {Link, useHistory} from "react-router-dom";

export default function HelpAndSupport() {
    const [loggedInUser, setLoggedInUser] = useContext(UserContext)

    const history = useHistory()

    useEffect(() => {
        if (!loggedInUser.user) {
            history.push('/login')
        }
    }, [])
    return (
        <div className='container mx-auto px-[16px] md:px-0'>
            <div className='mt-[82px] flex flex-col gap-5 md:flex-row justify-between'>
                <Link to='/chat'>
                    <img src="/assets/hs-1.png" alt=""/>
                    <div className='text-center text-[#003358]'>
                        chat now
                    </div>
                </Link>
                <Link to='/chat'>
                    <img src="/assets/hs-2.png" alt=""/>
                    {/*align below text to centre*/}
                    <div className='text-center text-[#003358]'>
                        chat now
                    </div>
                </Link>
                <Link to='/chat'>
                    <img src="/assets/hs-3.png" alt=""/>
                    <div className='text-center text-[#003358]'>
                        chat now
                    </div>
                </Link>
            </div>
            <h1 className='mt-[52px] text-center font-bold text-[40px] text-[#003358]'>How Can We Help You?</h1>
            <div className='bg-[#D9D9D9] rounded-full flex px-[18px] mt-[12px] py-[6px] w-full md:w-[500px] mx-auto'>
                <img src="/assets/icon_search.png" alt=""/>
                <input type="text" className='w-full pl-5 bg-[#D9D9D9]' placeholder='Search here...'/>
            </div>
            <br/>
            At SalesLine, we are committed to providing you with the best possible support to ensure your success in
            using our AI-powered Salesbook & Inventory Management app. Whether you're a new user looking for guidance or
            a long-time customer with specific questions, you'll find the resources and assistance you need right here.


            <div className='mt-[68px] grid grid-cols-2 gap-5 md:gap-[68px]'>
                <div className='col-span-2 md:col-span-1 p-4 border-[5px] border-[#D9D9D9] rounded-[18px]'>
                    <div className='flex items-center gap-2 text-[#003358]'>
                        <img src="/assets/icon_avatar.png" alt=""/>
                        <h2 className='text-[28px] font-semibold'>Getting Started</h2>
                    </div>
                    <p className='text-[20px] mt-2'>
                        If you're new to SalesLine and looking to get started, we've got you covered. Our Getting
                        Started section will walk you through the basics of setting up your account, importing your
                        data, and getting your team up to speed with the app. You'll find step-by-step guides, video
                        tutorials, and helpful tips to kickstart your SalesLine journey. </p>
                </div>
                <div className='col-span-2 md:col-span-1 p-4 border-[5px] border-[#D9D9D9] rounded-[18px]'>
                    <div className='flex items-center gap-2 text-[#003358]'>
                        <img src="/assets/icon_avatar.png" alt=""/>
                        <h2 className='text-[28px] font-semibold'>Frequently Asked Questions (FAQs)</h2>
                    </div>
                    <p className='text-[20px] mt-2'>
                        Have a quick question? Check out our FAQs section. We've compiled a list of common questions and
                        their answers to provide you with instant solutions. It's the quickest way to find answers to
                        questions like "How do I create a new product?" or "What are the system requirements for
                        SalesLine?" </p>
                </div>
                <div className='col-span-2 md:col-span-1 p-4 border-[5px] border-[#D9D9D9] rounded-[18px]'>
                    <div className='flex items-center gap-2 text-[#003358]'>
                        <img src="/assets/icon_avatar.png" alt=""/>
                        <h2 className='text-[28px] font-semibold'>Video Tutorials</h2>
                    </div>
                    <p className='text-[20px] mt-2'>
                        Visual learners, rejoice! Our library of video tutorials covers a wide range of topics, from the
                        basics of navigation to advanced features and tips. If you prefer to see how things are done,
                        this is the place for you.
                    </p>
                </div>
                <div className='col-span-2 md:col-span-1 p-4 border-[5px] border-[#D9D9D9] rounded-[18px]'>
                    <div className='flex items-center gap-2 text-[#003358]'>
                        <img src="/assets/icon_avatar.png" alt=""/>
                        <h2 className='text-[28px] font-semibold'>Knowledge Base</h2>
                    </div>
                    <p className='text-[20px] mt-2'>
                        Our Knowledge Base is your go-to resource for in-depth information. Here, you'll find detailed
                        articles on every aspect of SalesLine. Whether you're interested in advanced reporting,
                        inventory optimization, or integrations with other tools, you'll discover comprehensive guides
                        to help you master the app. </p>
                </div>
                <div className='col-span-2 md:col-span-1 p-4 border-[5px] border-[#D9D9D9] rounded-[18px]'>
                    <div className='flex items-center gap-2 text-[#003358]'>
                        <img src="/assets/icon_avatar.png" alt=""/>
                        <h2 className='text-[28px] font-semibold'>Contact Support</h2>
                    </div>
                    <p className='text-[20px] mt-2'>
                        Sometimes, you need personalized assistance. Our support team is here to help. You can reach out
                        to us directly through our Contact Support page. We're available to answer your questions,
                        provide guidance, and resolve any issues you may encounter. Your success is our priority. </p>
                </div>
                <div className='col-span-2 md:col-span-1 p-4 border-[5px] border-[#D9D9D9] rounded-[18px]'>
                    <div className='flex items-center gap-2 text-[#003358]'>
                        <img src="/assets/icon_avatar.png" alt=""/>
                        <h2 className='text-[28px] font-semibold'>Feedback and Suggestions</h2>
                    </div>
                    <p className='text-[20px] mt-2'>
                        We value your feedback. If you have ideas for improving SalesLine or encounter any issues,
                        please let us know through our Feedback and Suggestions page. Your input helps us shape the
                        future of the app.

                        At SalesLine, we're not just a software provider; we're your partner in success. Explore our
                        Help and Support resources to make the most of our AI-powered Salesbook & Inventory Management
                        app. Your journey to enhanced sales and inventory management efficiency starts here.

                    </p>
                </div>
            </div>

            <p className='mt-[100px] mb-[42px] text-center'>
                Stay in the loop with the latest updates, new features, and announcements from SalesLine. We're
                continually improving our app, and this section will keep you informed about what's new and
                exciting. </p>
        </div>
    )
}
