import Cookies from 'js-cookie';
import React, {useContext, useEffect, useState} from 'react';
import {Post} from '../../Adapters/xhr';
import {toast} from 'react-toastify';
import CustomTextField from '../../components/InputFields/CustomTextField';
import {useHistory, useLocation} from 'react-router-dom';
import {UserContext} from '../../App';
import { FcGoogle } from "react-icons/fc";
import { Link } from 'react-router-dom';
import {initializeApp} from "firebase/app";
import {getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signOut} from "firebase/auth";
import {firebaseConfig} from "../../Constants/firebase";
import {Button} from "@mui/material";
import {POST_LOGIN_URL} from "../../Constants/constants";

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export default function Login() {

    const [loggedInUser, setLoggedInUser] = useContext(UserContext);
    const [formValues, setFormValues] = useState({});
    const [googleFormValues, setGoogleFormValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const location = useLocation();
    let from = location.state?.from || { pathname: "/" };
    const history = useHistory();

    useEffect(() => {
        const loginWithGoogle = async () => {
            try {
                const response = await Post("loginWithGoogle", googleFormValues);
                if (response.data.access_token) {
                    setLoggedInUser({user_token: response.data.access_token, user: response.data.user});
                    Cookies.set("user_token", response.data.access_token);
                    toast("Login successful");
                    history.replace(from);
                } else {
                    toast("Login Failed");
                }
            } catch (err) {
                console.error(err);
                toast("Something went wrong");
                toast("Login Failed");
            }
        }

        if (Object.keys(googleFormValues).length > 0) {
            loginWithGoogle();
        }
    }, [googleFormValues]);

    const signInWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider).then(async (result) => {
                const authUser = result.user;
                if (authUser) {
                    const emailFromGoogle = authUser?.email;
                    const newFormData = {
                        email: emailFromGoogle,
                        password: Math.random().toString(36).slice(-8),
                    }
                    setGoogleFormValues(newFormData);
                } else {
                    alert("Login Failed");
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    const handleChange = (e) => {
        const newFormValues = {...formValues, [e.target.name]: e.target.value};
        setFormValues(newFormValues);
    }

    const loginSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await Post(POST_LOGIN_URL, formValues);
            if (response.data.access_token) {
                // console.log('user_token', JSON.stringify(response.data.access_token))
                // console.log('user', JSON.stringify(response.data.user))
                setLoggedInUser({user_token: response.data.access_token, user: response.data.user});
                Cookies.set("user_token", response.data.access_token);
                toast.success("Login successful");
                history.push(from);
            } else {
                toast.warn("Email or Password not Matched");
            }
        } catch (error) {
            toast.warn("Email or Password not Matched");
        }
        setLoading(false);
    }

    return (
        <div className='bg-[#003358] h-screen'>
            <div style={{backgroundImage: `url('/assets/auth-cover-art.png')`}} className='bg-[#003358] flex items-center bg-no-repeat bg-cover min-h-screen'>
                <div className='container w-full mx-auto p-[32px] flex justify-center'>
                    <div className='bg-white w-full md:w-[770px] min-h-[769px] rounded-2xl md:rounded-[78px] py-[44px] px-4 md:px-[110px]'>
                        <form onSubmit={loginSubmit}>
                            <div className='flex justify-center md:justify-end text-[16px] md:text-[20px] font-[Roboto-Slab] text-black text-opacity-50'>
                                <select>
                                    <option>English (UK)</option>
                                </select>
                            </div>
                            <h1 className='text-[36px] font-[Ramabhadra] text-[#212121] font-black text-center mt-[40px] md:mt-[68px]'>Log In</h1>
                            <div className='col-span-2 md:col-span-1 flex items-center justify-center gap-[14px] cursor-pointer border border-[#BCBCBC] rounded-[20px] py-2.5 px-4'>
                                <div className='flex items-center gap-2'>
                                    <Button onClick={signInWithGoogle}>
                                        <FcGoogle className='h-[36px] w-[36px]' />
                                        <span className='text-[15px] text-[#747474]'>Sign in with Google</span>
                                    </Button>
                                </div>
                            </div>
                            <div className="flex justify-center mt-[42px]">
                                <div className='flex items-center gap-3'>
                                    <div className='bg-[#979797] h-[1px] w-[60px]'></div>
                                    <div className='text-[#979797]'>OR</div>
                                    <div className='bg-[#979797] h-[1px] w-[60px]'></div>
                                </div>
                            </div>
                            <CustomTextField className={'w-full'} name="email" onChange={handleChange} label="Username or Email Address" type='email' required={true}/>
                            <div className='mt-4 md:mt-[35px]'>
                                <CustomTextField className={'w-full'} name="password" onChange={handleChange} label="Password" type='password' required={true}/>
                            </div>
                            <div className="flex justify-center">
                                <button disabled={loading} type='submit' className='text-[20px] mt-[48px] font-bold text-white py-[18px] px-[74px] bg-[#B38B00] rounded-[20px]'>
                                    Log In
                                </button>
                            </div>
                            <p className='text-[15px] text-[#747474] text-center mt-[15px]'>Don’t have an account? <Link to='/register'><span className='text-[#A38348]'>Sign up</span></Link></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
