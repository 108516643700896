import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl, GET_ALL_CONTEXTS_URL, GET_SINGLE_CONTEXT } from "../../../Constants/constants.js";

function DataComponent() {
    const [contexts, setContexts] = useState([]);
    const [loading, setLoading] = useState(false); // Added loading state
    const [formData, setFormData] = useState({});

    useEffect(() => {
        getContexts();
    }, []);

    const getContexts = async () => {
        try {
            const response = await axios.get(`${baseUrl}/${GET_ALL_CONTEXTS_URL}`);
            setContexts(response.data.body);
        } catch (error) {
            console.log("Error:", error);
            alert("An " + JSON.stringify(error.message) + " Error Occurred");
            // Handle error
        }
    };

    const fetchData = async (id) => {
        try {
            const response = await axios.get(`${baseUrl}${GET_SINGLE_CONTEXT}/${id}`);
            // todo work here 1st
            const formattedData = {};
            const lines = response.data.body.content.split("\n");
            lines.forEach((line) => {
                const [key, value] = line.split(",");
                formattedData[key] = value;
            });
            setFormData(formattedData);
        } catch (error) {
            console.log("Error:", error);
            alert("An " + JSON.stringify(error.message) + " Error Occurred");
            // Handle error
        }
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${baseUrl}/api/submit`, formData);
            // todo work here 2nd
            // Handle success
        } catch (error) {
            console.log("Error:", error);
            alert("An " + JSON.stringify(error.message) + " Error Occurred");
            // Handle error
        }
    };

    const handleInputChange = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    return (
        <>
            <div>
                <div className="flex h-full">
                    {/* LEFT IMAGE */}
                    <div className="bg_img"></div>

                    <div className="flex flex-col w-full h-screen bg-white-100 rounded-tl-[100px] rounded-bl-[100px] ml-[34.7%] relative z-10">
                        <div className="flex-col justify-center w-full h-full pl-[8.5%] max-w-[608px] mt-4">
                            {/* HEADING */}
                            <h3 className="uppercase text-black-300 text-f_36_l_68 font-ramabhadra font-black mb-4">
                                All Contexts
                            </h3>

                            {/* FORM */}
                            <table className="border-collapse w-full">
                                <tbody>
                                {contexts.map((context) => (
                                    <tr key={context.id}>
                                        <td className="p-[8px]">
                                            <button
                                                className="bg-brown rounded-[20px] text-white-100 text-f_20_l_23 font-bold w-full h-[58px] flex items-center justify-center"
                                                disabled={loading}
                                                id={context.id}
                                                onClick={() => fetchData(JSON.stringify(context.id))}
                                            >
                                                {loading ? "Fetching..." : context.value}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                            {/* Dynamic Input Texts */}
                            {Object.entries(formData).map(([key, value]) => (
                                <div key={key} className="flex my-4">
                                    <input
                                        type="text"
                                        value={key}
                                        onChange={(e) => handleInputChange(key, e.target.value)}
                                        className="border border-gray-300 rounded-l px-4 py-2 w-1/2"
                                    />
                                    <textarea
                                        value={value}
                                        onChange={(e) => handleInputChange(key, e.target.value)}
                                        className="border border-gray-300 rounded-r px-4 py-2 w-1/2"
                                    />
                                </div>
                            ))}
                            <div  className="flex my-4">
                                <input
                                    type="text"
                                    placeholder="New Question"
                                    // onChange={(e) => handleInputChange(key, e.target.value)}
                                    className="border border-gray-300 rounded-l px-4 py-2 w-1/2"
                                />
                                <textarea
                                    placeholder="Answer"
                                    // onChange={(e) => handleInputChange(key, e.target.value)}
                                    className="border border-gray-300 rounded-r px-4 py-2 w-1/2"
                                />
                            </div>
                            {/* Submit Button */}
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DataComponent;
