import React, { useContext, useEffect, useState } from "react";
import { Chart } from '../../../components/index';
import { UserContext } from "../../../App";
import {Get, Post} from "../../../Adapters/xhr";
import { toCommaAmount } from "../../../Constants/constants";
import { toast } from "react-toastify";

const DailyIncomeChart = () => {
    const [allpayment, setallpayment] = useState([]);
    const [filteredIncome, setFilteredIncome] = useState([]);
    const [loggedInUser, setLoggedInUser] = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [updateData, setUpdateData] = useState(null);
    const [totalAmount, setTotalAmount] = useState("0");

    let theFilteredIncome;

    const filterAllIncome = (thisYearsData) => {
        try {
            console.log("inside WEEKLY -----filterAllIncome");
            console.log(thisYearsData)
            let incomeMonth = 0;
            const chartDataPoints = [
                { label: 'Sun', value: 0 },
                { label: 'Mon', value: 0 },
                { label: 'Tue', value: 0 },
                { label: 'Wed', value: 0 },
                { label: 'Thur', value: 0 },
                { label: 'Fri', value: 0 },
                { label: 'Sat', value: 0 },
            ];

            thisYearsData.forEach((incomeObject, index) => {
                incomeMonth = new Date(incomeObject.date_time).getDay(); //starting at 0
                chartDataPoints[incomeMonth].value += +incomeObject.amount;
            });

            return chartDataPoints; // Return the calculated chart data points as an array
        } catch (error) {
            console.error("This error >> " + error)
            return null; // Return null when there's nothing to show
        }
    };

    const getWeekDayNumber = () => {
        const d = new Date();
        return d.getDay();
    };

    let WEEK_DAY_NUMBER = getWeekDayNumber();
    const today = new Date();
    const thisMonth = today.getMonth();

    const thisWeekStarting = today.getDate() - WEEK_DAY_NUMBER;

    const getAllPayments = async () => {
        try {
           const response = await Get("payment");
            console.log('DailyIncomeChart response data' , JSON.stringify(response.data))

            if (!response.data === false) {
                const thisWeeksData = [];

                response.data.data.forEach((data) => {
                    const date = data.date_time;
                    const createdMonth = new Date(date).getMonth();
                    const createdDate = new Date(date).getDate();

                    if (createdMonth === thisMonth) {
                        if (
                            today.getDate() >= createdDate &&
                            createdDate >= thisWeekStarting
                        ) {
                            thisWeeksData.push({
                                ...data,
                                customer: data?.customer,
                                product_name: data?.description,
                                organization: data?.organization_id
                            });

                            // function to loop through array of objects and add values of a field
                            let computedTotalAmount = thisWeeksData.reduce(
                                function (prev, cur) {
                                    return prev + +cur.amount;
                                },
                                0
                            );

                            computedTotalAmount = toCommaAmount(
                                computedTotalAmount + ""
                            );

                            setTotalAmount(computedTotalAmount);
                        }
                    }
                });

                const chartDataPoints = filterAllIncome(thisWeeksData);
                setFilteredIncome(chartDataPoints);
                setallpayment(thisWeeksData);
            }
            setLoading(false);
        } catch (error) {
            toast.warn("Try Again");
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllPayments();
    }, []);

    return (
        <div>
            {/* Render the Chart component with the calculated data */}
            {filteredIncome && (
                <Chart dataPoints={filteredIncome} />
            )}
        </div>
    );
};

export { DailyIncomeChart };
