import React, {useEffect, useState} from 'react';
import {
    DailyExpenseChart,
    DailyExpenseChartLastW,
    DailyIncomeChart,
    DailyIncomeChartLastW,
    ErrorBoundary,
    MonthlyExpenseChart,
    MonthlyIncomeChart,
    PrevPrevYearMonthlyIncomeChart,
    PrevYearMonthlyIncomeChart
} from "../../components";
import {fetchAllProducts} from "../../Constants/constants";

export default function Welcome() {
    const [loading, setLoading] = useState(true);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Step 2

    useEffect(() => {
        // Step 3: Update the current year when the component mounts
        setCurrentYear(new Date().getFullYear());
        /*const initializeProducts = async () => {
            await fetchAllProducts();
        };
        initializeProducts();*/
    }, []);

    function countDown() {
        setLoading(false);
    }

    setTimeout(countDown, 2000);

    return (
        <div className='w-full h-full'>
            <ErrorBoundary>
                <div className='w-full h-full'>
                    <div>
                        <h1 className='text-[32px] font-semibold text-[#003358]'>Welcome to <span>SalesLine</span></h1>
                        <p className='text-[20px] my-[22px]'>SalesLine is an innovative and cutting-edge AI-powered
                            Salesbook, Reconciliation &
                            Inventory Management application, meticulously crafted to address the sales tracking
                            challenges
                            faced by enterprises across diverse scales. Its sophisticated capabilities empower
                            businesses of all
                            sizes, offering a comprehensive solution to streamline sales operations and enhance
                            inventory
                            management efficiency..</p>
                        <h3 className='text-[32px] font-semibold text-[#003358]'>
                            Income for {currentYear}
                        </h3>

                        <p> {!loading &&
                            <MonthlyIncomeChart/>}
                            {loading
                                &&
                                <p> Loading..<br/>
                                </p>
                            }
                        </p>
                        &nbsp;
                        &nbsp;
                        &nbsp;

                        <h3 className='text-[32px] font-semibold text-[#003358]'>
                            Income for This Week
                        </h3>
                        <p>
                            {<DailyIncomeChart/>}
                        </p>

                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <h3 className='text-[32px] font-semibold text-[#003358]'>
                            Income for Last Week
                        </h3>

                        <p> {!loading &&
                            <DailyIncomeChartLastW/>}
                            {loading
                                &&
                                <p> Loading..<br/>
                                </p>
                            }
                        </p>
                        <hr/>
                        <h3 className='text-[32px] font-semibold text-[#003358]'>
                            Expenses for {currentYear}
                        </h3>

                        <p> {!loading &&
                            <MonthlyExpenseChart/>}
                            {loading
                                &&
                                <p> Loading..<br/>
                                </p>
                            }
                        </p>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <h3 className='text-[32px] font-semibold text-[#003358]'>
                            Expenses for This Week</h3>

                        <p> {!loading &&
                            <DailyExpenseChart/>}
                            {loading
                                &&
                                <p> Loading..<br/>
                                </p>
                            }
                        </p>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <h3 className='text-[32px] font-semibold text-[#003358]'>
                            Expenses for Last Week
                        </h3>

                        <p> {!loading &&
                            <DailyExpenseChartLastW/>}
                            {loading
                                &&
                                <p> Loading..<br/>
                                </p>
                            }
                        </p>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <h3 className='text-[32px] font-semibold text-[#003358]'>
                            Income for {currentYear - 1}
                        </h3>

                        <p> {!loading &&
                            <PrevYearMonthlyIncomeChart/>}
                            {loading
                                &&
                                <p> Loading..<br/>
                                </p>
                            }
                        </p>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <h3 className='text-[32px] font-semibold text-[#003358]'>
                            Income for {currentYear - 2}
                        </h3>

                        <p> {!loading &&
                            <PrevPrevYearMonthlyIncomeChart/>}
                            {loading
                                &&
                                <p> Loading..<br/>
                                </p>
                            }
                        </p>


                        {/*{!loading && !PrevPrevYearMonthlyIncomeChart() && !DailyIncomeChart() && !DailyIncomeChartLastW() && !MonthlyExpenseChart() && !DailyExpenseChart() && !DailyExpenseChartLastW() && (*/}
                        {/*    <h3 className='text-[32px] font-semibold text-[#003358]'>*/}
                        {/*        No Data*/}
                        {/*    </h3>*/}
                        {/*)}*/}
                    </div>
                </div>
            </ErrorBoundary>
        </div>
    )
}
