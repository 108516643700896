import React, { useContext, useEffect, useState } from "react";
import { Chart } from '../../../components/index';
import { UserContext } from "../../../App";
import {Get} from "../../../Adapters/xhr";
import { toCommaAmount } from "../../../Constants/constants";
import { toast } from "react-toastify";

const MonthlyIncomeChart = () => {
    const [allpayment, setallpayment] = useState([]);
    const [filteredIncome, setFilteredIncome] = useState([]);
    const [loggedInUser, setLoggedInUser] = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [updateData, setUpdateData] = useState(null);
    const [totalAmount, setTotalAmount] = useState("0");

    const filterAllIncome = (thisYearsData) => {
        try {
            let incomeMonth = 0;
            const chartDataPoints = [
                { label: 'Jan', value: 0 },
                { label: 'Feb', value: 0 },
                { label: 'Mar', value: 0 },
                { label: 'Apr', value: 0 },
                { label: 'May', value: 0 },
                { label: 'Jun', value: 0 },
                { label: 'Jul', value: 0 },
                { label: 'Aug', value: 0 },
                { label: 'Sep', value: 0 },
                { label: 'Oct', value: 0 },
                { label: 'Nov', value: 0 },
                { label: 'Dec', value: 0 },
            ];

            thisYearsData.forEach((incomeObject, index) => {
                incomeMonth = new Date(incomeObject.date_time).getMonth();
                chartDataPoints[incomeMonth].value += +incomeObject.amount;
            });

            return chartDataPoints;
        } catch (error) {
            console.error("This error >> " + error);
            return null;
        }
    };

    const getAllPayments = async () => {
        try {
            const response = await Get("payment",);

            console.log('MonthlyIncomeChart response data' , JSON.stringify(response.data))


            if (!response.data === false) {
                const thisYearsData = [];

                response.data.data.forEach((data) => {
                    const date = data.date_time;
                    const createdDate = new Date(date).getFullYear();
                    const today = new Date();
                    const thisYear = today.getFullYear();

                    if (createdDate === thisYear) {
                        thisYearsData.push({
                            ...data,
                            customer: data?.customer,
                            product_name: data?.description,
                            organization: data?.organization_id
                        });

                        const theFilteredIncome = filterAllIncome(thisYearsData);

                        setFilteredIncome(theFilteredIncome);

                        let computedTotalAmount = thisYearsData.reduce(function (prev, cur) {
                            return prev + +cur.amount;
                        }, 0);

                        computedTotalAmount = toCommaAmount(computedTotalAmount + "");
                        setTotalAmount(computedTotalAmount);
                    }
                });
                setallpayment(thisYearsData);
            }
            setLoading(false);
        } catch (error) {
            toast.warn("Try Again");
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllPayments();
    }, []);

    return (
        <div>
            {filteredIncome && (
                <Chart dataPoints={filteredIncome} />
            )}
        </div>
    );
};

export { MonthlyIncomeChart };
