import React, { useState } from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import AllContexts from "./AllContexts.jsx";
import {ADD_CONTEXT_URL, baseUrl} from "../../../Constants/constants";

function AddContext() {
    const [id, setId] = useState("");
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);
    const signup_logo = "/assets/signup_logo.png";

    const handleIdChange = (e) => {
        setId(e.target.value);
    };

    const handleKeyChange = (e) => {
        setKey(e.target.value);
    };

    const handleValueChange = (e) => {
        setValue(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (id && key && value) {
                const contextData = {
                    id: parseInt(id),
                    key,
                    value,
                };
                const response = await axios.post(`${baseUrl}/${ADD_CONTEXT_URL}`, contextData);
                console.log("Response:", response);
                alert("Context added successfully");
            } else {
                alert("Please fill in all fields");
            }
        } catch (error) {
            console.log("Error:", error);
            alert("An error occurred while adding context");
        } finally {
            setLoading(false); // Set loading state to false after request completion
            setId("");
            setKey("");
            setValue("");
        }
    };

    return (
        <>
            <div>
                <div className="flex h-full">
                    {/* LEFT IMAGE */}
                    <div className="bg_img">
                        <div className=" w-fit absolute bottom-[2.5%] left-[5.8%]">
                            <img src={signup_logo} alt="logo" />
                        </div>
                    </div>

                    <div className="flex flex-col w-full h-screen bg-white-100 rounded-tl-[100px] rounded-bl-[100px] ml-[34.7%] relative z-10">

                        <div className="flex flex-col justify-center w-full h-full pl-[8.5%] max-w-[608px] mt-32">
                            {/* HEADING */}
                            <h3 className="uppercase text-black-300 text-f_36_l_68 font-ramabhadra font-black mb-8">
                                Add A New Context
                            </h3>

                            {/* FORM */}
                            <form onSubmit={handleSubmit}>
                                <div className="px-3 space-y-12 mb-[5px]">
                                    {/* ID */}
                                    <input
                                        className="border border-grey-400 w-full text-f_18 placeholder:text-grey-400 p-[20px]"
                                        placeholder="ID"
                                        onChange={handleIdChange}
                                        value={id}
                                        type="text"
                                        id="key"
                                        required
                                    />

                                    {/* KEY */}
                                    <input
                                        className="border border-grey-400 w-full text-f_18 placeholder:text-grey-400 p-[20px]"
                                        placeholder="Key"
                                        onChange={handleKeyChange}
                                        value={key}
                                        type="text"
                                        id="key"
                                        required
                                    />

                                    <input
                                        className="border border-grey-400 w-full text-f_18 placeholder:text-grey-400 p-[20px]"
                                        placeholder="Value"
                                        onChange={handleValueChange}
                                        value={value}
                                        type="text"
                                        id="value"
                                        required
                                    />

                                    {/* CREATE ACCOUNT */}
                                    <div className="px-12">
                                        <button className="bg-brown rounded-[20px] text-white-100 text-f_20_l_23 font-bold w-full h-[58px] flex items-center justify-center"
                                                disabled={loading}>
                                            {loading ? "Adding..." : "Add Context"}
                                        </button>
                                    </div>
                                </div>

                                {/* LOGIN */}
                                <AllContexts/>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddContext;
