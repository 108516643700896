import Cookies from 'js-cookie';
import React, { useContext, useState } from 'react';
import { Post } from '../../Adapters/xhr';
import { toast } from 'react-toastify';
import CustomTextField from '../../components/InputFields/CustomTextField';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../../App';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { firebaseConfig } from '../../Constants/firebase';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

// Initialize Firebase app (you can do this in firebase.js if you prefer)
const app = initializeApp(firebaseConfig);

// Get the Auth instance
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

export default function OrgDetails() {
    const [loggedInUser, setLoggedInUser] = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [organisation, setOrganisation] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);

    const location = useLocation();
    let from = location.state?.from || { pathname: '/' }; // Use location state for redirect info
    const history = useHistory();

    const finishSignUp = async (e) => {
        e.preventDefault(); // Prevent the form from submitting automatically

        try {
            // Get formValues from the location state
            const authUser = auth.currentUser;
            const emailFromGoogle = authUser?.email;
            const nameFromGoogle = authUser?.displayName;
            const photoUrlFromGoogle = authUser?.photoURL;

            const newFormValues = {
                username: emailFromGoogle,
                email: emailFromGoogle,
                name: nameFromGoogle,
                // Generate a random password for the user
                password: Math.random().toString(36).slice(-8),
            };

            const response = await Post('registerWithGoogle', {
                ...newFormValues,
                organisation,
                isAdmin,
            });

            console.log('response from registerWithGoogle');
            console.log(response);

            if (response.status === 201) {
                // setLoggedInUser({ user_token: response.data.token, user: response.data.user });
                // Cookies.set('user_token', response.data.token);
                toast('Sign up successful');
                history.replace('login');
            } else {
                toast('Something went wrong');
                console.error('Sign up failed');
            }
        } catch (err) {
            toast('Something went wrong');
            console.error(err);
        }
    };

    const handleChange = (e) => {
        setOrganisation(e.target.value);
    };

    const handleAdminChange = (e) => {
        setIsAdmin(e.target.checked);
    };

    return (
        <div className="bg-[#003358] h-screen">
            <div style={{ backgroundImage: 'url("/assets/auth-cover-art.png")' }} className="bg-[#003358] flex items-center bg-no-repeat bg-cover min-h-screen">
                <div className="container w-full mx-auto p-[32px] flex justify-center">
                    <div className="bg-white w-full md:w-[770px] min-h-[769px] rounded-2xl md:rounded-[78px] py-[44px] px-4 md:px-[110px]">
                        <form onSubmit={finishSignUp}>
                            <div className="flex justify-center md:justify-end text-[16px] md:text-[20px] font-[Roboto-Slab] text-black text-opacity-50">
                                <select>
                                    <option>English (UK)</option>
                                </select>
                            </div>
                            <h1 className="text-[36px] font-[Ramabhadra] text-[#212121] font-black text-center mt-[40px] md:mt-[68px]">Organisation Name</h1>
                            <CustomTextField className="w-full" name="organisation" onChange={handleChange} label="Enter your Organisation name here" type="text" required={true} />
                            <div className="flex justify-center mt-5">
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox onChange={handleAdminChange} />} label="Is Admin" />
                                </FormGroup>
                            </div>
                            <div className="flex justify-center">
                                <button disabled={loading} type="submit" className="text-[20px] mt-[48px] font-bold text-white py-[18px] px-[74px] bg-[#B38B00] rounded-[20px]">Finish Sign up</button>
                            </div>
                            <p className="text-[15px] text-[#747474] text-center mt-[15px]">Final step</p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
