import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Delete, Get } from "../../Adapters/xhr";
import { UserContext } from "../../App";
import MUIDialog from "../../components/Dailog/MUIDialog";
import DataTable from "../../components/DataTable/MUITable";
import UpdatePayment from "../AddPayment/UpdatePayment";
import { getProductNameByCode, toCommaAmount } from "../../Constants/constants";
import SingleIncomePrint from "./SingleIncomePrint";
import { Chart } from "../../components";

export default function IncomeThisWeek() {
    const [allPayments, setAllPayments] = useState([]);
    const [filteredIncome, setFilteredIncome] = useState([]);
    const [loggedInUser, setLoggedInUser] = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [updateData, setUpdateData] = useState(null);
    const [totalAmount, setTotalAmount] = useState("0");
    const [open2, setOpen2] = useState(false);
    const [rowData, setRowData] = useState(null);

    const filterAllIncome = (thisYearsData) => {
        try {
            console.log("inside filterAllIncome");
            let incomeMonth = 0;
            const chartDataPoints = [
                { label: "Sun", value: 0 },
                { label: "Mon", value: 0 },
                { label: "Tue", value: 0 },
                { label: "Wed", value: 0 },
                { label: "Thur", value: 0 },
                { label: "Fri", value: 0 },
                { label: "Sat", value: 0 }
            ];

            thisYearsData.forEach((incomeObject) => {
                incomeMonth = new Date(incomeObject.date_time).getDay();
                chartDataPoints[incomeMonth].value += +incomeObject.amount;
            });

            return <Chart dataPoints={chartDataPoints} />;
        } catch (error) {
            console.error("This error >> " + error);
        }
    };

    const getWeekDayNumber = () => {
        const d = new Date();
        return d.getDay();
    };

    const WEEK_DAY_NUMBER = getWeekDayNumber();
    const today = new Date();
    const thisMonth = today.getMonth();
    const thisYear = today.getFullYear();
    const thisWeekStarting = today.getDate() - WEEK_DAY_NUMBER;

    const getAllPayments = async () => {
        try {
            const response = await Get("payment");
            console.log("log:", response);

            if (response.data !== false) {
                const thisWeeksData = await Promise.all(
                    response.data.data
                        .filter((data) => {
                            const date = new Date(data.date_time);
                            const createdMonth = date.getMonth();
                            const createdDate = date.getDate();
                            const createdYear = date.getFullYear();

                            return (
                                createdYear === thisYear &&
                                createdMonth === thisMonth &&
                                today.getDate() >= createdDate &&
                                createdDate >= thisWeekStarting
                            );
                        })
                        .map(async (data) => {
                            data.product_name = await getProductNameByCode(data?.product_id);
                            return data;
                        })
                );

                const theFilteredIncome = filterAllIncome(thisWeeksData);
                setFilteredIncome(theFilteredIncome);

                const computedTotalAmount = thisWeeksData.reduce((prev, cur) => prev + +cur.amount, 0);
                setTotalAmount(toCommaAmount(computedTotalAmount.toString()));

                setAllPayments(thisWeeksData);
            }

            setLoading(false);
        } catch (error) {
            toast.warn("Try Again");
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllPayments();
    }, [open]);

    const columns = [
        { field: "product_name", title: "Name", width: 70 },
        { field: "description", title: "Description", width: 70 },
        { field: "amount", title: "Amount", width: 70 },
        { field: "customer", title: "Customer", width: 70 }
    ];

    const handleDelete = async (rowData) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                Delete(`payment/deletepayment/${rowData._id}`)
                    .then((result) => {
                        setAllPayments(result.data);
                        Swal.fire("Deleted!", "Your file has been deleted.", "success");
                    })
                    .catch((err) => {
                        toast.warn("Try Again!");
                    });
            }
        });
    };

    const handlePrint = (rowData) => {
        setRowData(rowData);
        setOpen2(true);
        console.log("hit");
    };

    return (
        <div>
            {!loading && (
                <DataTable
                    loading={loading}
                    tableTitle="This Week Income"
                    columns={columns}
                    rows={allPayments}
                    handleEdit={(rowData) => {
                        setUpdateData(rowData);
                        setOpen(true);
                    }}
                    handleDelete={handleDelete}
                    handlePrint={handlePrint}
                />
            )}
            {loading && <p>Loading..<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;</p>}
            <MUIDialog
                open={open}
                setOpen={setOpen}
                content={<UpdatePayment updateData={updateData} setOpen={setOpen} />}
            />
            <MUIDialog
                open={open2}
                setOpen={setOpen2}
                content={<SingleIncomePrint rowData={rowData} />}
            />
            <p>
                <b>Total: {totalAmount}</b>
            </p>
            <p>
                &nbsp;
                <div>{filteredIncome}</div>
            </p>
        </div>
    );
}
