import {Button} from '@mui/material'
import React, {useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {toast} from 'react-toastify';
import {register} from '../../Adapters/auth/register';
import CustomTextField from '../../components/InputFields/CustomTextField';
import {FcGoogle} from "react-icons/fc";
// Import necessary Firebase modules
import {initializeApp} from "firebase/app";
import {getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {firebaseConfig} from "../../Constants/firebase";
import {SEND_REGISTER_EMAIL_URL} from "../../Constants/constants";

// Initialize Firebase app (you can do this in firebase.js if you prefer)
const app = initializeApp(firebaseConfig);

// Get the Auth instance
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

export default function Register() {

    const history = useHistory()

    const [formValues, setFormValues] = useState({});
    const [googleFormValues, setGoogleFormValues] = useState({})
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    console.log(auth?.currentUser?.email);

    const signUpWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            const authUser = auth.currentUser;
            console.log('before authUser');
            console.log(authUser)
            const emailFromGoogle = authUser?.email;
            const nameFromGoogle = authUser?.displayName;
            const displayNameFromGoogle = authUser?.displayName;
            const photoUrlFromGoogle = authUser?.photoURL;
            const newFormValues = {
                username: emailFromGoogle,
                email: emailFromGoogle,
                name: nameFromGoogle,
                // generate a random password for the user,
                photoUrlFromGoogle: photoUrlFromGoogle,
                password: Math.random().toString(36).slice(-8),
            };
            setGoogleFormValues(newFormValues);
            console.log('after authUser');
            console.log(googleFormValues);
            await handleSendRegistrationMailGoogle();
            // navigte to a new screen were the user can enter his organisation name
            // and other details
            // pss the email, name, formValues object and photoUrl to the next screen
            history.push("/org-details", {googleFormValues: googleFormValues});
        } catch (err) {
            console.error(err);
        }
    };

    const handleChange = (event) => {
        const newFormValues = formValues;
        newFormValues[event.target.name] = event.target.value;
        setFormValues(newFormValues);
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        console.log({formValues})
        setLoading(true)

        try {

            const response = await register("register", {formValues})
            console.log({response})
            await handleSendRegistrationMail(event);
            if (response.status === 201) {
                toast.success("Register Successful")
                history.push("/login")
            }
            setLoading(false)

        } catch (error) {
            toast.warn("Register Failed. Try Again")
            setLoading(false)
        }
    }

    const handleSendRegistrationMailGoogle = async (event) => {
        event.preventDefault();
        setLoading(true);
        const formValues = googleFormValues;

        try {
            const response = await fetch(SEND_REGISTER_EMAIL_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({formValues})
            });
            const data = await response.json();
            // console.log(data);
        } catch (error) {
            console.log(error);
            alert('An error occurred');
        }finally {
            setLoading(false);
        }
    };

    const handleSendRegistrationMail = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(SEND_REGISTER_EMAIL_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({formValues})
            });
            const data = await response.json();
            // console.log(data);
        } catch (error) {
            console.log(error);
            alert('An error occurred');
        }finally {
            setLoading(false);
        }
    };




    return (
        <div className='bg-[#003358] h-screen'>
            <div style={{backgroundImage: `url('/assets/auth-cover-art.png')`}}
                 className='bg-[#003358] flex items-center bg-no-repeat bg-cover min-h-screen'>
                <div className='container w-full mx-auto p-[32px] flex justify-center'>
                    <div
                        className='bg-white w-full md:w-[770px] min-h-[769px] rounded-2xl md:rounded-[78px] py-[44px] px-4 md:px-[110px]'>
                        <form>
                            <div
                                className='flex justify-center md:justify-end text-[16px] md:text-[20px] font-[Roboto-Slab] text-black text-opacity-50'>
                                <select>
                                    <option>English (UK)</option>
                                </select>
                            </div>
                            <h1 className='text-[36px] font-[Ramabhadra] text-[#212121] font-black text-center mt-[40px] md:mt-[68px]'>CREATE
                                AN ACCOUNT</h1>


                            <div
                                className='col-span-2 md:col-span-1 flex justify-center items-center gap-[14px] cursor-pointer'>
                                <div className='border border-[#BCBCBC] rounded-[20px] py-2.5 px-4'>


                                    <Button
                                        onClick={signUpWithGoogle}
                                    >
                                        <FcGoogle
                                            className='h-[36px] w-[36px]'
                                        />
                                        <span className='text-[15px] text-[#747474]'>Sign up with Google</span>
                                    </Button>
                                </div>

                            </div>

                            <div className="flex justify-center mt-[42px]">
                                <div className='flex items-center gap-3'>
                                    <div className='bg-[#979797] h-[1px] w-[60px]'></div>
                                    <div className='text-[#979797]'>OR</div>
                                    <div className='bg-[#979797] h-[1px] w-[60px]'></div>
                                </div>
                            </div>

                            <CustomTextField className={'w-full'} name="username" onChange={handleChange}
                                             label="Username" type='text'
                                             required={true}/>
                            <CustomTextField className={'w-full'} name="organisation" onChange={handleChange}
                                             label="Organisation"
                                             type='text' required={true}/>
                            <CustomTextField className={'w-full'} name="name" onChange={handleChange} label="Full Name"
                                             type='text' required={true}/>
                            <CustomTextField className={'w-full'} name="email" onChange={handleChange} label="Email"
                                             type='email'
                                             required={true}/>
                            <div className='mt-[35px]'>
                                <CustomTextField className={'w-full'} name="password" onChange={handleChange}
                                                 label="Password" type='password'
                                                 required={true}/>
                            </div>


                            <div className="flex justify-center">
                                <button disabled={loading}
                                        type='submit'
                                        className='text-[20px] mt-[18px] font-bold text-white py-[18px] px-[74px] bg-[#B38B00] rounded-[20px]'
                                        onClick={handleSubmit}
                                >
                                    Create Account
                                </button>
                            </div>

                            <p className='text-[15px] text-[#747474] text-center mt-[15px]'>Already have an
                                account? <Link to="/login">Log in</Link></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
