import Cookies from "js-cookie";
import {Post} from "../Adapters/xhr";
import {getCache, setCache} from "./cache";
import axios from 'axios';

let productDataCache = null;

export const headerOptions = {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    user_token = Cookies.get("user_token"),
    // baseUrl = "http://127.0.0.1:8000/api/user";
    baseUrl = "https://salesline.ng/backend02/api/user";
    // baseUrl = "https://d66k64jwpa.us-east-1.awsapprunner.com/user"; // previous
    // baseUrl = "https://gf7mtukyyk.us-east-1.awsapprunner.com/user"; // previous

export const CONTACT_API_URL =
    'https://17o5g7wjd1.execute-api.us-east-1.amazonaws.com/main/contactus/'

export const GET_ALL_ARTICLES_URL = baseUrl + "/news/"
export const SEND_REGISTER_EMAIL_URL = baseUrl + '/sendRegisterMail'

export const POST_LOGIN_URL = 'login'
export const POST_GET_PRODUCT_BY_ID_URL = 'getProductNameByCode'
export const ALL_PRODUCTS_URL = 'productOrService'
export const READ_DOC_URL = '/read_doc'
export const GET_ALL_CONTEXTS_URL = '/llmContext'
export const GET_SINGLE_CONTEXT = '/context'
export const ADD_CONTEXT_URL = 'add_context'
export const ADD_PAYMENT_URL = 'payment/add'

export const toCommaAmount = (origNum) => {
    let newNum = origNum
    if (origNum !== null) {
        if (typeof origNum == 'string') {
            newNum = Number(origNum);
        }
        const options = {
            style: 'currency',
            currency: 'NGN',
            currencyDisplay: 'symbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            currencySign: 'accounting'
        }

        const intNum = new Intl.NumberFormat('en-NG', options).format(newNum);
        const formatted = intNum.replace(/(?<=\d)NGN/g, '\u20A6');
        return formatted.toString()
    }
}

export const formatDate = input => {
    if (input)
    {
        const date = new Date(input);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour12: false,
            timeZone: 'UTC'
        };

        const formatter = new Intl.DateTimeFormat('en-US', options);
        return formatter.format(date);
    }
};


export const fetchAllProducts = async () => {
    console.log('inside fetchAllProducts')
    const response = await fetch(ALL_PRODUCTS_URL);
    console.log('fetched products >> ', JSON.stringify(response))
    if (response.ok) {
        const products = await response.json();
        localStorage.setItem('all_products', JSON.stringify(products));
    } else {
        console.error('Failed to fetch all products');
    }
};

export const getProductNameByCode = async (productCode) => {
    if (!productDataCache) {
        try {
            const response = await axios.get('https://salesline.ng/products.json');
            productDataCache = response.data.productorservices; // Assuming the products are in 'productorservices' key

            console.log('Fetched product data:', productDataCache);

            if (!Array.isArray(productDataCache)) {
                throw new Error('Invalid JSON response: Expected an array');
            }

        } catch (error) {
            console.error('Error fetching products:', error);
            return 'Unknown Product';
        }
    }

    const product = productDataCache.find(p => p.product_id === productCode);
    return product ? product.name : 'Unknown Product';
};


export const formatDateTime = input => {
    if (input)
    {
        const date = new Date(input);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZone: 'UTC'
        };

        const formatter = new Intl.DateTimeFormat('en-US', options);
        return formatter.format(date);
    }
};
